<template>
  <div class="turbo-features">
    <h3 class="stox-title">خدمة التخزين</h3>
    <div class="stox-content">
      <div>
        <p>نحن سعداء بتقديم خدمة التخزين المجانية و المتابعة
          بالشراكة مع <a target="_blank" href="https://stox.info/">STOX</a></p>
        <p>
          تهدف هذه الخدمة إلى تخزين منتجاتك تحضير طلباتك وتغليفها بدلا عنك واكثر...
        </p>
        <a class="stox-subscribe" href="https://wa.me/201016881336" target="_blank">اشترك</a>
      </div>
      <div class="stox-img">
        <img src="@/assets/images/elements/logo-stox.png" alt="TURBO FEATURES">
      </div>
    </div>
    <!-- <h3 class="turbo-features-title">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.8718 8.77422C15.361 8.68597 15.829 9.01097 15.9173 9.50013L16.2746 11.4808L16.7021 10.5787C16.851 10.2644 17.1676 10.0641 17.5154 10.0641C17.8631 10.0641 18.1798 10.2644 18.3287 10.5787L18.7565 11.4815L19.1143 9.49998C19.2027 9.01083 19.6708 8.68591 20.16 8.77425C20.6491 8.86258 20.974 9.33072 20.8857 9.81986L20.0295 14.5608C19.96 14.9457 19.6499 15.2416 19.2622 15.2931C18.8745 15.3445 18.498 15.1397 18.3305 14.7863L17.5154 13.0659L16.7002 14.7863C16.5327 15.1397 16.1562 15.3445 15.7684 15.2931C15.3807 15.2416 15.0706 14.9456 15.0012 14.5607L14.1459 9.81971C14.0576 9.33055 14.3826 8.86247 14.8718 8.77422ZM7.68468 8.75992C8.18174 8.75992 8.58468 9.16286 8.58468 9.65992V14.445C8.58468 14.8315 8.33789 15.1749 7.97152 15.2981C7.60516 15.4213 7.20105 15.2968 6.96752 14.9888L5.05879 12.4714V14.5842C5.05879 15.0813 4.65585 15.4842 4.15879 15.4842C3.66173 15.4842 3.25879 15.0813 3.25879 14.5842V9.79483C3.25879 9.40831 3.50558 9.06496 3.87195 8.94177C4.23831 8.81858 4.64242 8.94307 4.87595 9.25106L6.78468 11.7684V9.65992C6.78468 9.16286 7.18762 8.75992 7.68468 8.75992ZM9.49161 9.65992C9.49161 9.16286 9.89455 8.75992 10.3916 8.75992H12.9869C13.484 8.75992 13.8869 9.16286 13.8869 9.65992C13.8869 10.157 13.484 10.5599 12.9869 10.5599H11.2916V11.1306H12.513C13.0101 11.1306 13.413 11.5335 13.413 12.0306C13.413 12.5276 13.0101 12.9306 12.513 12.9306H11.2916V13.5009H12.9869C13.484 13.5009 13.8869 13.9038 13.8869 14.4009C13.8869 14.8979 13.484 15.3009 12.9869 15.3009H10.3916C9.89455 15.3009 9.49161 14.8979 9.49161 14.4009V9.65992Z"
          fill="white"
        />
        <g opacity="0.4">
          <path
            d="M3.1001 4.68906C3.1001 4.19201 3.50304 3.78906 4.0001 3.78906H19.56C20.0571 3.78906 20.46 4.19201 20.46 4.68906C20.46 5.18612 20.0571 5.58906 19.56 5.58906H4.0001C3.50304 5.58906 3.1001 5.18612 3.1001 4.68906Z"
            fill="white"
          />
          <path
            d="M3.1001 19.3092C3.1001 18.8121 3.50304 18.4092 4.0001 18.4092H19.56C20.0571 18.4092 20.46 18.8121 20.46 19.3092C20.46 19.8062 20.0571 20.2092 19.56 20.2092H4.0001C3.50304 20.2092 3.1001 19.8062 3.1001 19.3092Z"
            fill="white"
          />
        </g>
      </svg>
      مميزات تربو
    </h3>
    <transition-group :name="dirSlide" class="img-slider" tag="div">
      <div class="features-slide" v-for="i in [currentIndexItems]" :key="i">
        <div class="features-slide-captions">
          <h2 class="features-slide-title">{{ currentItem.title }}</h2>
          <p class="features-slide-text">
            {{ currentItem.text }}
          </p>
        </div>
        <div class="features-slide-img">
          <img :src="currentItem.img" alt="Turbo Features Image" />
        </div>
      </div>
    </transition-group>
    <div class="turbo-features-footer">
      <svg
        @click="next(), stopAutoSlide()"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 8H15M15 8L8 1M15 8L8 15"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ currentIndexItems + 1 }}/{{ items.length }}
      <svg
        @click="prev()"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 8H1M1 8L8 15M1 8L8 1"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          title: "فتح الشحنة",
          text: `نسبة تسليمات أعلى من خلال إمكانية فتح الشحنة والمعاينة قبل الاستلام .`,
          img: require("@/assets/images/elements/turbo-features1.png"),
        },
        {
          title: "التسليم الجزئى",
          text: `نسبة تسليمات أعلى مع خدمة المرتجع الجزئي لأن عميلك هيقدر يقارن بين أكتر من منتج و يختار 
المناسب ليه .`,
          img: require("@/assets/images/elements/turbo-features2.png"),
        },
        {
          title: "البيك اب",
          text: `وصل طرودك بكل سهولة مع عملية بيك أب متاحة بأكثر من طريقة زي الفرع أو زيارة المندوب ليك .`,
          img: require("@/assets/images/elements/turbo-features3.png"),
        },
        {
          title: "مسؤول خاص لحسابك",
          text: `بنوفرلك متابعة استثنائية مع مسؤول خاص لحسابك من خدمة العملاء عشان يساعدك تتابع وتوصل 
لكل الحلول .`,
          img: require("@/assets/images/elements/turbo-features4.png"),
        },
        {
          title: "أكثر من ٦٥ فرع",
          text: `تربو أقرب، إحنا أقرب ليك و لعميلك بأكبر شبكة لوچيستيات و٦٥ فرع على نطاق محافظات مصر  .`,
          img: require("@/assets/images/elements/turbo-features5.png"),
        },
        {
          title: "حصل توريدك بكل سهولة",
          text: `طرق توريد متعددة، بنوردلك بأكثر من طريقة بيتم طلبهم من السيستم أو التطبيق .`,
          img: require("@/assets/images/elements/turbo-features6.png"),
        },
        {
          title: "تطبيق خاص بيك",
          text: `تربو أسهل، مع تطبيق مرن متاح على كل المنصات هتقدر تنزل التطبيق مهما كان نظامك التشغيلي  .`,
          img: require("@/assets/images/elements/turbo-features7.png"),
        },
        {
          title: "متابعة فورية للشحنات",
          text: `يمكنك متابعة الطرود بشكل فوري عن طريق كود الشحنة من خلال السيستم أو التطبيق .`,
          img: require("@/assets/images/elements/turbo-features8.png"),
        },
        {
          title: "سهولة التواصل مع المندوب",
          text: `يقدم سيستم تربو خاصية التواصل على رسائل الطرد مع المندوب توفيرا للوقت مما يساهم في سرعة عملية الشحن و التسليم .`,
          img: require("@/assets/images/elements/turbo-features9.png"),
        },
        {
          title: "إمكانية ربط السيستم",
          text: `تربو سهلتها عليك وبتوفرلك خاصية ربط سيستم تربو بالسيستم الخاص بيك .`,
          img: require("@/assets/images/elements/turbo-features10.png"),
        },
        {
          title: "تقيـيم الخدمة",
          text: `يمكنك تقييم أداء الخدمة مع كل شحنة من خلال السيستم أو التطبيق مما يساهم في تطوير مستوى الخدمة المقدمة من خلال شركة تربو .`,
          img: require("@/assets/images/elements/turbo-features11.png"),
        },
        {
          title: "تذاكر الدعم",
          text: `يمكنك التواصل مع خدمة العملاء من خلال تذاكر الدعم عن طريق السيستم أو التطبيق و متابعة كل ما يخص عملك من شحنات .`,
          img: require("@/assets/images/elements/turbo-features12.png"),
        },
        {
          title: "الشحن الدولي",
          text: `متاح الشحن الدولي من مصر الي جميع دول العالم .ويوجد التحصيل عند الاستلام في السعودية والامارات فقط .`,
          img: require("@/assets/images/elements/turbo-features13.png"),
        },
      ],
      currentIndexItems: 0,
      dirSlide: "rtl",
      autoSlide: undefined,
    };
  },
  computed: {
    currentItem() {
      return this.items[Math.abs(this.currentIndexItems) % this.items.length];
    },
  },
  methods: {
    next() {
      this.dirSlide = "rtlSlide";
      this.currentIndexItems += 1;
      if (this.currentIndexItems == this.items.length) {
        this.currentIndexItems = 0;
      }
    },
    prev() {
      this.dirSlide = "ltrSlide";
      this.currentIndexItems -= 1;
      if (this.currentIndexItems < 0) {
        this.currentIndexItems = this.items.length - 1;
      }
      this.stopAutoSlide();
    },
    stopAutoSlide() {
      clearInterval(this.autoSlide);
    },
  },
  mounted() {
    this.autoSlide = setInterval(() => {
      this.next();
    }, 4000);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_turboFeatures.scss";
.turbo-features {
  display: flex;
  flex-direction: column;
}
.stox-title {
  font-size: 20px;
  font-weight: bold;
  color: #000;
  position: relative;
  width: fit-content;
  line-height: normal;
  margin-bottom: 13px;
  &::after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -9px;
    width: 51%;
    height: 5px;
    background-color: #f48914;
  }
}
.stox-content {
  height: 100%;
  display: flex;
  p {
    font-size: 18px;
    font-weight: normal;
    color: #000;
    line-height: normal;
    a {
      color: #f48914;
      font-weight: 900;
      font-size: 20px;
    }
    &:nth-last-child(even) {
      font-size: 16px;
      font-weight: 300;
      color: #000;
      margin-bottom: 11px;
      font-weight: 300;
    }
  }
  .stox-subscribe {
    padding: 1px 22px 3px 23px;
    border-radius: 18.5px;
    background-color: #f48914;
    border: 0px;
    color: #fff;
    font-size: 18px;
    font-weight: normal;
  }
  .stox-img {
    background-image: url("~@/assets/images/elements/bg-stox.png");
    background-size: cover;
    background-position: center;
    min-width: 225px;
    display: flex;
    border-bottom-right-radius: 24px;
  }
  img {
    align-self: end;
    transform: translate(56px, -34px);
  }
}
@media (min-width: 300px) and (max-width: 767px) {
  .turbo-features {
    padding: 24px 26px !important;
  }
  .stox-content {
    flex-wrap: wrap;
    p {
      width: 100%;
    }
    .stox-img {
      display: none;
    }
  }
}
</style>
