<template>
  <div class="parent-modal">
    <div class="modal-add">
      <div class="header">
        <h3 class="title">{{ title }}</h3>
        <i @click="closeModal()">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 1L1 13M1 1L13 13"
              stroke="#31006F"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </i>
      </div>
      <div class="body">
        <slot name="body"></slot>
      </div>
      <div class="footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
  },
  props: {
    title: {
      default: "أضافة",
      required: true,
      type: String,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/_customizeTooltip.scss";
</style>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_variables.scss";
body {
  pointer-events: none !important;
}
.parent-modal {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
}
.modal-add::-webkit-scrollbar {
  width: 0.125rem;
  height: 10.8125rem;    
  opacity: 50%;
}

.modal-add::-webkit-scrollbar-track {
  background: none;
}

.modal-add::-webkit-scrollbar-thumb {
  background: #70707052;
  opacity: 50%;
}
.modal-add {
  pointer-events: all;
  outline: 9999px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  z-index: 999999;
  background-color: #fff;
  right: 50%;
  transform: translate(50%, -50%);
  top: 50%;
  padding: 24px 16px;
  border-radius: 24px;
  width: 785px;
  max-height: 500px;
  overflow-y: auto;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
    margin-bottom: 24px;
    border-bottom: solid 1px #d9d9d9;
    width: 100%;
    .title {
      font-size: 20px;
      font-weight: 700;
      color: $mainColor1;
    }
    i {
      cursor: pointer;
    }
  }
  .body {
    margin-bottom: 50px;
    width: 100%;
    .modal-input {
      margin-bottom: 16px;
      label {
        font-size: 16px;
        line-height: 30px;
        font-weight: 500;
        color: #666666;
        margin-bottom: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      input {
        outline: none;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 8px;
        padding: 6px 8px;
        font-family: Cairo;
        font-size: 16px;
        font-weight: 600;
        line-height: 30px;
        color: $mainColor1;
      }
      .disable-input {
        background-color: #f0f0f0;
        cursor: no-drop;
      }
    }
  }
  .footer {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    button {
      padding: 8px 16px;
      font-weight: 700;
      font-size: 18px;
      line-height: 33px;
      color: #fff;
      border: none;
      background-color: #9e9e9e;
      border-radius: 4px;
      cursor: pointer;
    }
    .second-btn {
      background-color: #fff;
      color: $mainColor1;
      border: 1px solid $mainColor1;
    }
    .active-btn {
      background-color: $mainColor1;
    }
  }
}
</style>
