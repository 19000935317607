<template>
  <div>
    <input
      v-if="typeInput == 'text' || typeInput == 'number'"
      type="text"
      name="updateClientData"
      @input="$emit('input', $event.target.value)"
    />
    <SelectInput
      v-else-if="typeInput == 'dropdown'"
      :options="survey_answers"
      ref="surveyDropdown"
      @input="$emit('input', $refs.surveyDropdown.selected.id)"
    />
    <div
      v-else-if="typeInput == 'checkbox'"
      style="display: grid; gap: 4px; grid-template-columns: repeat(12, 1fr)"
    >
      <vs-checkbox
        style="grid-column: span 6"
        name="checkbox"
        class="remember m-0 mt-5"
        :ref="'surveyCheckbox' + item.id"
        @input="surveyCheckbox(item.id)"
        v-for="item in survey_answers"
        >{{ item.name }}</vs-checkbox
      >
    </div>
    <span style="font-size: 0.7rem" class="text-danger font-bold">{{
      success_submit_survey === 0
        ? err_survey
        : errors.first("updateClientData")
    }}</span>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SelectInput from "../../layouts/components/SelectInput.vue";
export default {
  data() {
    return {
      survey_answers: [],
      survey_checkbox: [],
    };
  },
  components: {
    SelectInput,
  },
  computed: {
    ...mapState("survey", [
      "surveyData",
      "err_survey",
      "success_submit_survey",
      "available_surveys",
    ]),
    handleSurveyAnswers() {
      let values = Object.values(this.surveyData.survey_answers);
      let keys = Object.keys(this.surveyData.survey_answers);
      for (let index = 0; index < keys.length; index++) {
        this.survey_answers.push({ id: keys[index], name: values[index] });
      }
    },
  },
  methods: {
    findIndex(id) {
      return this.survey_checkbox.findIndex((checkboxId) => checkboxId == id);
    },
    surveyCheckbox(id) {
      if (this.findIndex(id) < 0) {
        this.survey_checkbox.push(id);
      } else {
        this.survey_checkbox.splice(this.findIndex(id), 1);
      }
      this.$emit("input", this.survey_checkbox);
    },
  },
  props: {
    typeInput: {
      default: "text",
      required: true,
      type: String,
    },
  },
  created() {
    this.handleSurveyAnswers;
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
input {
  outline: none;
  width: 100%;
  border: 1px solid #d9d9d9;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  color: $mainColor1;
}
</style>
